import './App.css';
import { Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import NavBar from './components/NavBar';
import Title from './components/Title';
import Menu from './components/Menu';
import About from './components/About';
import Reviews from './components/Reviews';
import Popular from './components/Popular';
import { useEffect, useState } from 'react';
import Intro from './components/Intro';
import { AnimatePresence, motion } from 'framer-motion';

function App() {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const first = localStorage.getItem("firstVisit");
    if (!first) {
      localStorage.setItem("firstVisit", "visited");
    }

    const timer = setTimeout(() => {
      setShowIntro(false); // Hide the intro after 1.5 seconds
    }, 500);

    return () => clearTimeout(timer); // Clear timeout on unmount
  }, []);

  return (
    <div className='app'>
      <AnimatePresence>
        {showIntro && (
          <Intro key="intro" />
        )}
      </AnimatePresence>

      {!showIntro && (
        <motion.div
          initial={{ opacity: 0 }}  // Start invisible
          animate={{ opacity: 1, transition: { duration: 1.5 } }}  // Fade in over 1.5 seconds
        >
          <Title />
          <NavBar />
          <Routes>
            <Route path='/' element={[<Hero key="hero" />, <Reviews key="reviews" />, <About key="about" />]} />
            <Route path='/menu' element={[<Popular key="popular" />, <Menu key="menu" />]} />
          </Routes>
        </motion.div>
      )}
    </div>
  );
}

export default App;
