import React from 'react'

const Reviews = () => {



  return (
    <div className='reviews'>
        <h1>Reviews</h1>
        <div className='reviewCardContainer'>
            <div className='reviewCard'>
                <p>Best Pepperoni Pizza Hands Down! Best Cheese. Best Crust. Best Pizza In Town! Great Prices!</p>
                <p>⭐⭐⭐⭐⭐</p>
                <p className='reviewer'>-John Kendall</p>
            </div>
            <div className='reviewCard'>
                <p>Pizza is FANTASTIC! The crust and sauce remind me of authentic Italian pizza. The home cured and smoked meats are extra special. This pizza is better than any place I go in Denver.</p>
                <p>⭐⭐⭐⭐⭐</p>
                <p className='reviewer'>-Ashley Rust</p>
            </div>
            <div className='reviewCard'>
                <p>Love this place it’s the best Pizza in town and Darren is a very friendly guy and fun to talk to while the pizza is cooking.</p>
                <p>⭐⭐⭐⭐⭐</p>
                <p className='reviewer'>-Mike Paws</p>
            </div>
        </div>
    </div>
  )
}

export default Reviews