import React from 'react'
import owner from './assets/owner.jpg'
import pizza from './assets/IMG_0198.jpg'
import pizza1 from './assets/pizza1.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const About = () => {
  return (
    <div className='about'>
        <div className='aboutCont'>
            <div>
                <h1>About Us</h1>
                <p>At Fahrenheit 851, we're all about bringing the authentic taste of Italy straight to your neighborhood. Our pizzas are crafted with love, using only the freshest ingredients and cooked to perfection in our traditional brick ovens. Whether it's our signature Margherita or a custom creation, every slice delivers that crispy, flavorful experience you crave. We believe in good food, good vibes, and making every meal a celebration. Come grab a slice and taste the difference!</p>
            </div>
            <Carousel showThumbs={false} swipeable={true} showStatus={false} infiniteLoop={true} autoPlay={true} showIndicators={false}>
                <img src={owner} alt="" />
                <img src={pizza} alt="" />
                <img src={pizza1} alt="" />
            </Carousel>
        </div>
    </div>
  )
}

export default About