import React from 'react'
import pizza from './assets/IMG_0198.jpg'
import cowboy from './assets/IMG_0195.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Popular = () => {
  return (
    <div className='popular'>
        <h1>Best Sellers</h1>
        <Carousel className='best-car' showThumbs={false} swipeable={true} showStatus={false} infiniteLoop={true} autoPlay={true} showIndicators={false}>
            <div className='best-item'>
                <img src={pizza} alt="" />
                <h3>Carnivore Pizza</h3>
            </div>
            <div className='best-item'>
                <img src={cowboy} alt="" />
                <h3>Cowboy Pizza *Also Available in Sandwich*</h3>
            </div>
        </Carousel>
    </div>
  )
}

export default Popular