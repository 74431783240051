import React from 'react'
import pizza from './assets/pizza1.jpg'
import pizza1 from './assets/pizza2.jpg'
import pizza2 from './assets/pizza3.jpg'
import pizza3 from './assets/IMG_0198.jpg'

const Hero = () => {
  return (
    <div className='hero'>
        <div className='heroCont'>
            <img src={pizza} alt="" />
            <img src={pizza1} alt="" />
            <img src={pizza2} alt="" />
            <img src={pizza3} alt="" />
        </div>
        <div className='heroTxt'>
            <h1>Brick Oven Pizzas</h1>
            <p>Indulge in the authentic flavors of our brick oven pizzas, crafted with love using only the freshest ingredients. Experience a slice of tradition with every bite, where quality meets taste on wheels.</p>
            <a href='tel:7206598789' className='call'>Call Now</a>
        </div>
    </div>
  )
}

export default Hero