import React from 'react';
import fire from './assets/1717965-sd_640_360_25fps.mp4';
import { motion } from 'framer-motion';

const Intro = () => {
  return (
    <motion.div
      className='intro-title'
      initial={{ opacity: 1 }} // Start fully visible
      animate={{ opacity: 1 }} // Remain fully visible during this phase
      exit={{ opacity: 0, transition: { duration: .5 } }}  // Fade out over 1.5 seconds
    >
      <div className='intro-video-section'>
        <video src={fire} loop autoPlay muted></video>
        <div className='intro-video-copy'>
          <h1>Fahrenheit 851</h1>
        </div>
      </div>
    </motion.div>
  );
};

export default Intro;
