import React from 'react';
import fire from './assets/1717965-sd_640_360_25fps.mp4';

const Title = () => {
  return (
    <div className='title'>
      <div className='videoSection'>
        <video 
          src={fire} 
          loop 
          autoPlay 
          playsInline  // Ensures it doesn't open fullscreen on iOS
          muted 
          preload="auto" // Helps with faster loading
        ></video>
        <div className='videoCopy'>
          <h2>Fahrenheit 851</h2>
        </div>
      </div>
    </div>
  )
}

export default Title;
