import React from 'react'
import logo from './assets/logo.png'
import { Link } from 'react-router-dom'
import { FaFacebook } from "react-icons/fa";

const NavBar = () => {
  return (
    <div className='nav'>
        <img src={logo} alt="" />
        <div>
            <Link to='/'>
                <p>Home</p>
            </Link>
            <Link to='/menu'>
                <p>Menu</p>
            </Link>
            {/* <Link to='/gallery'>
                <p>Check Out Our Pizzas</p>
            </Link> */}
        </div>
        <div className='socials'>
            <a href="tel:7206598789" className='call'>Call Now!</a>
            <Link to='https://www.facebook.com/p/Fahrenheit-851-Pizza-61558587745111'>
                <FaFacebook size={50} id='facebook'/>
            </Link>
        </div>
    </div>
  )
}

export default NavBar